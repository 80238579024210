body {
  margin: 0;
  background-image: linear-gradient(
      to bottom right,
      rgb(1, 29, 46) 5%,
      rgb(1, 50, 78) 15%,
      rgb(2, 60, 94) 30%,
      rgba(47, 7, 121, 0.8) 75%,
      rgba(1, 29, 46, 0.3) 90%
    ),
    radial-gradient(#e66465, #9198e5);
}
.App {
  height: 100vh;
  display: flex;
}
@media screen and (max-width: 1000px) {
  .App {
    flex-direction: column;
    align-items: center;

    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 400px) {
  .App {
    width: 90%;
  }
}
.form {
  flex: 1;
  width: 30%;
  padding: 0.5em;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}
@media screen and (max-width: 1000px) and (min-width: 400px) {
  .form {
    justify-content: space-around;
    flex-direction: row;
    width: 90%;
  }
}
input {
  color: rgb(2, 37, 53);
}
.cityname-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.cityname-block > input {
  padding: 0.5em;
  margin: 0.5em 0;
}
@media screen and (max-width: 400px) {
  .form {
    width: 100%;
  }
}
.form > form {
  display: flex;
  flex-direction: column;
}
form > input {
  margin-top: 1px;
  padding: 0.5em;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
}
.pdf {
  flex: 3;
  /* width: 70%; */
  margin: 1em;
}
@media screen and (max-width: 1000px) {
  .pdf {
    width: 90%;
    height: 70%;
  }
}
@media screen and (max-width: 400px) {
  .pdf {
    width: 100%;
  }
}
.viewer-inner-page {
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 400px) {
  .viewer-layout-toolbar {
    display: none;
  }
}
.viewer-layout-container {
  border: none;
}
.dpt {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .dpt {
    flex-direction: row;
  }
  .dpt > .cityname-block {
    align-items: start;
    margin-left: 15px;
  }
}

@keyframes orbit {
  from {
    box-shadow: 55px 55px 6px rgba(230, 38, 198, 0.75);
    transform: rotate(-90deg);
  }
  15% {
    box-shadow: 55px 55px 6px aliceblue;
  }
  85% {
    opacity: 1;
  }
  to {
    transform: rotate(270deg);
    opacity: 0;
  }
}
.dpt > p {
  text-align: center;
}
.dpt > .dpt-submitted {
  position: relative;
}
.dpt-submitted::before {
  content: '';
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 15px;
  height: 15px;
  background-color: rgb(214, 16, 49);
  border-radius: 100px;
  box-shadow: 55px 55px 6px aliceblue;
  opacity: 1;
  transform: translate(-50%, -50%) rotate(-90deg);
  animation: orbit 1s ease-in-out forwards;
}

.dpt-input > input {
  position: relative;
  height: 150px;
  width: 150px;
  background-color: rgb(0, 43, 70);
  border-radius: 500px;
  border: 3px solid transparent;
  box-shadow: 0 0 0 1px aliceblue;
  margin: 0 auto;
  color: aliceblue;
  text-align: center;
  font-size: 80px;
}
.dpt-input > input:focus {
  outline: none;
}
.dpt-input > input::placeholder {
  font-size: 18px;
}
@media screen and (max-width: 400px) {
  .dpt {
    margin-bottom: 25px;
  }
  .dpt-input > input {
    height: 100px;
    width: 100px;
    font-size: 50px;
  }
  .cityname-block > h1,
  .cityname-block > p {
    margin: 0px;
  }
  .cityname-block > h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 1000px) {
  .dpt-input > input {
    margin: 0;
  }
}
.inputs {
  display: flex;
}
input[type='text']:focus {
  border: 3px solid rgba(230, 38, 198, 0.75);
  box-shadow: 0 0 0 1px transparent;
  outline: transparent;
}
form > input[type='text'] {
  font-size: 16px;
  color: aliceblue;
}
form > input[type='text']:focus {
  font-weight: bold;
  border: 3px solid rgba(240, 248, 255, 0.7);
}
input[type='submit'] {
  margin: 15px 0;
  background-color: rgb(230, 38, 86);
  color: aliceblue;
  font-weight: bold;
  font-size: 14px;
}
input[type='submit']:focus,
input[type='submit']:hover {
  outline: 3px solid rgba(230, 38, 86, 0.75);
}
input[type='submit']:disabled {
  background-color: rgba(230, 38, 86, 0.349);
  color: rgba(240, 248, 255, 0.377);
}
.filter_mode {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  position: relative;
}
.filter_mode:hover {
  cursor: pointer;
}
.filter_mode__on {
  transition: background-color 0.5s, color 0.5s;
  background-color: palevioletred;
  color: palevioletred;
}
.filter_mode__off {
  transition: background-color 0.5s, color 0.5s;
  background-color: rgba(240, 248, 255, 0.3);
  color: rgba(240, 248, 255, 0.3);
}
.filter_mode > div {
  color: rgb(6, 62, 110);
  font-weight: bold;
}
.filter_mode > .spans {
  position: absolute;
  top: 0%;
  left: 150%;
  width: 150px;
  color: inherit;
}

.filled-field {
  border: 1px solid green;
}
.arrow {
  position: absolute;
  background-color: rgb(1, 69, 126);
  color: aliceblue;
  font-size: 24px;
  padding: 7px;
  margin: 10px;
  border: 1px rgba(0, 83, 131, 0.7) solid;
  border-radius: 50px;
  box-shadow: 8px 3px 8px -5px rgba(63, 1, 59, 0.75);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.arrow:hover {
  border: 1px rgba(161, 210, 238, 0.863) solid;
  box-shadow: 15px 15px 15px -5px rgba(63, 1, 59, 0.5);
}
.arrow:active {
  border: 1px rgb(230, 38, 86) solid;
  box-shadow: 5px 5px 5px -5px rgb(0, 27, 43);
}
.arrow-disabled {
  color: rgba(240, 248, 255, 0.377);
  border: 1px rgba(0, 83, 131, 0.1) solid;
  box-shadow: none;
  cursor: not-allowed;
}
.right,
.left {
  top: 33.33%;
}
.left {
  left: 0%;
}
.right {
  right: 0%;
}
@media screen and (max-width: 1000px) {
  .right,
  .left {
    top: 50%;
    transform: translateY(-50%);
  }
  .left {
    left: -15%;
  }
  .right {
    right: -15%;
  }
}
@media screen and (max-width: 400px) {
  .right,
  .left {
    top: 75%;
  }
  .left {
    left: 0%;
  }
  .right {
    right: 0%;
  }
}

.alt-display {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  justify-content: space-between;

  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.5)
  );
  overflow: hidden;
}
.alt-display > .txt {
  margin: auto 5em;
}
.alt-display > .txt:first-child {
  flex: 1;
}
.dept_to_update {
  color: aliceblue;
  height: 90%;
  flex: 3;
}
.dept_to_update > h2 {
  margin-bottom: 10px !important;
}
.dept_subtitle > p {
  margin: 0;
}
.dept_subtitle > p:first-child > strong {
  font-size: 22px;
}
.dept_list {
  overflow: scroll;
  height: 95%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.dept_item {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.39);
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  margin: 5px;
  transition: border 0.2s, box-shadow 0.2s, background-color 0.2s;
}
.dept_item:hover {
  border: 1px solid white;
  box-shadow: 0px 5px 10px -3px rgb(231, 145, 159);
  background-color: rgba(231, 145, 159, 0.15);
}
.dept_item > p {
  margin: 0;
}
.dept_item > p:first-child {
  font-weight: bold;
  font-size: 20px;
}
.dept_item > p:nth-child(2) {
  font-size: 14px;
}
@media screen and (max-width: 700px) {
  .alt-display {
    justify-content: start;
  }
  .alt-display > .txt {
    margin: 5em 2em;
    width: 70%;
  }
}
.txt {
  color: aliceblue;
  width: 30%;
  letter-spacing: 1px;
  /* line-height: 15px; */
}
.txt > h2 {
  margin-bottom: 2em;
  word-spacing: 3px;
}
@keyframes ghost-move {
  from {
    right: 15%;
    top: 50%;
    font-size: 80px;
  }
  12.5% {
    top: 45%;
  }
  25% {
    top: 50%;
  }
  50% {
    right: 30%;
    top: 45%;
    font-size: 142px;
  }
  62.5% {
    top: 50%;
  }
  75% {
    top: 45%;
  }
  to {
    right: 15%;
    top: 50%;
    font-size: 80px;
  }
}
.icon-ghost {
  position: absolute;
  top: 50%;
  right: 15%;
  font-size: 142px;
  color: rgba(240, 248, 255, 0.15);
  animation: ghost-move ease-in-out 10s infinite;
}
.icon-fun {
  z-index: -1;
  position: absolute;
  font-size: 580px;
  color: rgba(240, 248, 255, 0.15);

  top: 65%;
  left: 75%;
  transform: translate(-50%, -50%);
}
.icon-chat {
  position: absolute;
  font-size: 180px;
  color: rgba(240, 248, 255, 0.15);

  top: 15%;
  left: 40%;
  transform: rotateY(180deg);
}
.icon-intro {
  z-index: -1;
  position: absolute;
  font-size: 580px;
  color: rgba(240, 248, 255, 0.15);

  top: 65%;
  left: 75%;
  transform: translate(-50%, -50%);
}
.icon-star {
  font-size: 46px;
}
.line {
  display: flex;
  justify-content: space-evenly;

  position: absolute;
  top: 15%;
  right: 15%;
  width: 50%;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.presc_tvx {
  color: aliceblue;
  /* border: 1px solid; */
  margin-top: 0.5rem;
}
.loader-pprs {
  animation: spinner 2s infinite ease-in-out;
  display: flex;
}
.toggle {
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
}
.toggle::before,
.toggle::after {
  position: absolute;
  content: '';
  bottom: 0.5rem;
  height: 1px;
  width: 30%;
  background-color: rgba(240, 248, 255, 0.5);
}
.toggle::before {
  left: 10%;
}
.toggle::after {
  right: 10%;
}
.pprs {
  margin-top: 1rem;
}
.pprs > .active {
  border: 1px solid aliceblue;
}
.pprs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pprs > div {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.15);
}
.list_pprn {
  max-height: 150px;
  overflow: scroll;
}
.risk_nat {
  padding: 0.35rem 0;
}
.risk_nat:hover {
  cursor: pointer;
  user-select: none;
}
.risk_nat > p.ppr_title {
  margin: 0;
}
.risk_nat > p.ppr_desc {
  font-size: 10px;
  margin-top: 0;
  margin-bottom: 0;
}
.risk_nat.active {
  color: palevioletred;
}
.ppr_dates {
  display: flex;
  justify-content: space-between;
}
.ppr_dates p {
  margin: 0;
  font-size: 10px;
  color: inherit;
}
.ppr_dates p:first-child {
  margin-right: 1.5rem;
}
